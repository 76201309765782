import React, { Component } from 'react';
import RelatorioAssistServicoService from '../../service/RelatorioAssistServicoService';
import { RelatorioAssistServicoErro } from './RelatorioAssistServicoErro';

export class RelatorioAssistServico extends Component {
    constructor(props) {		
        super(props);

        this.state = {
            id: this.props.id,
            redirectSuccess: null,
            infoAssistServico: {
                perguntasList: [],
                ocorrenciasList: [],
                fotosList: [],
                pecasMercadoriaList: []
            }
        };

        let body = document.getElementsByTagName("body")[0];
        body.style.maxWidth = 'none';

		this.relatorioAssistServicoService = new RelatorioAssistServicoService();
    }

    componentWillMount(){
	}

    componentDidMount() {
		this.onRelatorioAssistServico(this.state.id);  
    }

    onRelatorioAssistServico(id) {
        this.relatorioAssistServicoService.find(id).then(res => {
            let bodyResponse = res.data;

            if(bodyResponse.dataAgendamento) {
                bodyResponse.dataAgendamento = new Date(Number(bodyResponse.dataAgendamento)).toLocaleDateString() + " " + new Date(Number(bodyResponse.dataAgendamento)).toLocaleTimeString();
            }

            bodyResponse.perguntasList = bodyResponse.perguntasList.filter(pergunta => pergunta.respostaChecklist);

            if(bodyResponse) {
                this.setState({
                    redirectSuccess: true,
                    infoAssistServico: bodyResponse
                });
            } else {
                alert("Não foi possível recuperar os dados do serviço.");
                this.setState({redirectSuccess: false});
            }
        })
        .catch((err) => {
            alert(err.response.data);
            this.setState({redirectSuccess: false});
        });
    }

    getRespostaPergunta(perguntaChecklist) {
        let resposta = perguntaChecklist.respostaChecklist;

        if(perguntaChecklist.alternativasList.length > 0) {
            resposta = perguntaChecklist.alternativasList.find(respostaChecklist => respostaChecklist.alternativa === perguntaChecklist.respostaChecklist)?.descricao;
        }

        return resposta + "";
    }

    getDecodeImageBase64(base64) {
        return "data:image/jpeg;base64, " + window.atob(base64);
    }

    render() {

        var perguntasChecklist = this.state.infoAssistServico.perguntasList;
        var ocorrenciasChecklist = this.state.infoAssistServico.ocorrenciasList;
        var fotos = this.state.infoAssistServico.fotosList;
        var pecasSolicitadas = this.state.infoAssistServico.pecasMercadoriaList;

        var styles = {
			navbarMaxwidth: { 'maxWidth': 'none' },
			fontWeightBold: { 'fontWeight': 'bold' },
			tituloH1: { 'textAlign': 'center', 'fontWeight': 'bold', 'marginBottom': '15px' },
            marginHr: { 'marginTop': '20px', 'marginBottom': '20px' },
            table: { 'margin': '0', 'borderSpacing': '0' },
            tableCell: { 'color': '#000', 'textAlign': 'center', 'border': '1px solid #000', 'borderRadius': '0', 'padding': '8px' },
            divFotos: { 'display': 'flex', 'justifyContent': 'space-between', 'flexWrap': 'wrap' },
            img: { 'marginBottom': '15px' }
		}

        if(this.state.redirectSuccess != null) {
			if(!this.state.redirectSuccess) {
				return <RelatorioAssistServicoErro />
			}
		} else {
            return <div className="load-screen"></div>
        }

        return (
            <div className="p-grid">
    			<nav className="navbar" style={ styles.navbarMaxwidth }>
					<img className="logoMMS" alt="MMS" src="/assets/layout/images/logoMMS.png"/>
				</nav>

                <div className="row">
                    <div className="p-col-12">
                        <label style={ styles.fontWeightBold }>Titular: </label>
                        <span>{ this.state.infoAssistServico.nomeTitular }</span>
                    </div>
                </div>

                <div className="row">
                    <div className="p-col-12">
                        <label style={ styles.fontWeightBold }>Certificado: </label>
                        <span>{ this.state.infoAssistServico.apolice }</span>
                    </div>
                </div>

                <div className="row">
                    <div className="p-col-12">
                        <label style={ styles.fontWeightBold }>Data de execução: </label>
                        <span>{ this.state.infoAssistServico.dataAgendamento ? this.state.infoAssistServico.dataAgendamento : "-" }</span>
                    </div>
                </div>

                <div className="row">
                    <div className="p-col-12">
                        <label style={ styles.fontWeightBold }>Serviço: </label>
                        <span>{ this.state.infoAssistServico.tipoServico }</span>
                    </div>
                </div>

                <hr style={ styles.marginHr }/>

                {/* PERGUNTAS CHECKLIST */}
                <h1 style={ styles.tituloH1 }>Checklist</h1>

                {
                    perguntasChecklist.length > 0 ? (
                        perguntasChecklist.map((pergunta, index) => (
                            <div key={ index }>
                                <div className="row">
                                    <label style={ styles.fontWeightBold }>{ index+1 }. { pergunta.descricaoPergunta }</label>
                                </div>
                                <p>{ this.getRespostaPergunta(pergunta) }</p>
                            </div>
                        ))
                    ) : (
                        <div className='row'>
                            <label style={ styles.fontWeightBold }>Não houve o preenchimento do checklist para este serviço.</label>
                        </div>
                    )
                }

                <hr style={ styles.marginHr }/>

                {/* OCORRÊNCIAS */}
                <h1 style={ styles.tituloH1 }>Ocorrências</h1>

                {
                    ocorrenciasChecklist.length > 0 ? (
                        <table style={ styles.table }>
                            <thead>
                                <tr>
                                    <th style={ styles.tableCell }>Id Ocorrência</th>
                                    <th style={ styles.tableCell }>Descrição</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    ocorrenciasChecklist.map((ocorrencia, index) => (
                                        <tr key={ index }>
                                            <td style={ styles.tableCell }>{ ocorrencia.idExterno }</td>
                                            <td style={ styles.tableCell }>{ ocorrencia.descricao }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    ) : (
                        <div className='row'>
                            <label style={ styles.fontWeightBold }>Não foram geradas ocorrências para este serviço.</label>
                        </div>
                    )

                }

                <hr style={ styles.marginHr }/>

                {/* PEÇAS SOLICITADAS */}
                <h1 style={ styles.tituloH1 }>Peças Solicitadas</h1>

                {
                    pecasSolicitadas.length > 0 ? (
                        <table style={ styles.table }>
                            <thead>
                                <tr>
                                    <th style={ styles.tableCell }>Id Mercadoria</th>
                                    <th style={ styles.tableCell }>Id Peça</th>
                                    <th style={ styles.tableCell }>Descrição</th>
                                    <th style={ styles.tableCell }>Quantidade Solicitada</th>
                                    <th style={ styles.tableCell }>Motivo Solicitação</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    pecasSolicitadas.map((pecas, index) => (
                                        <tr key={ index }>
                                            <td style={ styles.tableCell }>{ pecas.codigoMercadoria }</td>
                                            <td style={ styles.tableCell }>{ pecas.codigoPeca }</td>
                                            <td style={ styles.tableCell }>{ pecas.descricaoPeca }</td>
                                            <td style={ styles.tableCell }>{ pecas.quantidadeSolicitada }</td>
                                            <td style={ styles.tableCell }>{ pecas.descricaoMotivoInventario }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    ) : (
                        <div className='row'>
                            <label style={ styles.fontWeightBold }>Não houve à solicitação de peças neste serviço.</label>
                        </div>
                    )
                }

                <hr style={ styles.marginHr }/>

                {/* FOTOS */}
                <h1 style={ styles.tituloH1 }>Fotos</h1>

                <div style={ styles.divFotos }>
                    {
                        fotos.length > 0 ? (
                            fotos.map((foto, index) => (
                                <img src={ this.getDecodeImageBase64(foto.filesService[0].content) } alt={'Imagem ' + (index + 1) + " de " + this.state.infoAssistServico.tipoServico} style={ styles.img } width="300" height="400" key={ index } />
                            ))
                        ) : (
                            <div className='row'>
                                <label style={ styles.fontWeightBold }>Não houve o cadastro de fotos para este serviço.</label>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}