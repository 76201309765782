import AbstractService from './AbstractService';

class RelatorioAssistServicoService {
    constructor() {             
      this.abstractService = new AbstractService();
    }

    find(id) {		
		  return this.abstractService.get('/relatorio-assistservico/' + id + '/token');
    }
}

export default RelatorioAssistServicoService;