import React, {Component} from 'react';

export class RelatorioAssistServicoErro extends Component {

    componentWillMount() { }

    render() {
        return (
            <div className="p-grid">
                <nav className="navbar" style={{ 'maxWidth': 'none' }}>
					<img className="logoMMS" alt="MMS" src="/assets/layout/images/logoMMS.png"/>
				</nav>

                <div className="p-col-12">
                    <div className='txtOpiniao'>
                        <p><b>Ocorreu um erro ao carregar as informações do serviço.</b></p>
                    </div>
                </div>
            </div>
        );
    }
}