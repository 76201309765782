import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ExibeAgendamento } from "./components/agendamento/ExibeAgendamento";
import { ExibeContrato } from "./components/contrato/ExibeContrato";
import { ExibePesquisa } from "./components/pesquisa/ExibePesquisa";
import { ExibeRelatorioAssistServico } from "./components/relatorioAssistServico/ExibeRelatorioAssistServico";

import "./App.css";

class App extends Component {
  constructor() {
    super();
  }

  render() {

    return (

        <BrowserRouter>
            <Switch>

              <Route path="/contrato/:id" name="ExibeContrato" exact component={ExibeContrato} />
              <Route path="/contratoEnviado" name="ContratoEnviado" exact component={ExibeContrato} />

              <Route path="/pesquisa/:id" name="Pesquisa" exact component={ExibePesquisa} />
              <Route path="/pesquisa-respondida" name="PesquisaRespondida" exact component={ExibePesquisa} />
              <Route path="/pesquisa-erro" name="PesquisaErro" exact component={ExibePesquisa} />

              <Route path="/agendamento" name="Agendamento" exact component={ExibeAgendamento} />
              <Route path="/agendamento/cadastro" name="AgendamentoCadastro" exact component={ExibeAgendamento} />
              <Route path="/agendamento/concluido" name="AgendamentoConcluido" exact component={ExibeAgendamento} />

              <Route path="/relatorio-assistservico/:id" name="RelatorioAssistServico" exact component={ExibeRelatorioAssistServico} />
              <Route path="/relatorio-assistservico-erro" name="RelatorioAssistServicoErro" exact component={ExibeRelatorioAssistServico} />

            </Switch>
        </BrowserRouter>

    );
	
  }
}

export default App;
